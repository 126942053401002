import React from 'react'
import SVG from 'react-inlinesvg'

export const directTableContainerStyles = `
  .title {
    h4 {
      display: inline-block;
      margin: 0;
      font-size: 20px;
    }
  }

  .panel {
    padding: 24px;
  }

  .header {
    display: flex;
    flex-direct: row;
    align-items: center;

    .add {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 46px;
      padding: 14px 20px 12px;
      border-radius: 4px;
      color: white;
      background: var(--text-primary);
      font-size: 16px;

      svg {
        margin-top: -2px;
        margin-left: 10px;
      }
    }
  }
`

export const directTableStyles = `
  max-width: calc(100% + 48px);
  margin: 30px -24px 20px;
  overflow: auto;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 5px;
    background: inherit;
  }

  ::-webkit-scrollbar-track {
    margin-left: 7px;
    margin-right: 7px;
    background: var(--text-muted);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: var(--text-secondary-light);
  }

  &.no-scroll {
    overflow: hidden !important;

    table {
      margin-bottom: 5px !important;
    }
  }

  table {
    margin-bottom: 0px;
  }

  thead {
    white-space: nowrap;

    th,
    td {
      height: 32px;
      padding: 8px 16px;
      color: #bcbfca;
      font-size: 12px;
      font-weight: normal;
      cursor: pointer;
    }

    td {
      text-align: center;
    }
  }

  tbody {
    color: var(--text-primary);
    font-size: 12px;
    white-space: nowrap;

    th,
    td {
      position: relative;
      height: 52px;
      padding: 8px 16px;
      font-weight: normal;
      vertical-align: middle;
    }

    th {
      font-size: 12px;
      white-space: nowrap;
    }

    td {
      text-align: center;
      font-size: 12px;
      font-weight: normal;
    }

    .with-separator {
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 25%;
        right: 0;
        bottom: 25%;
        width: 1px;
        background: var(--text-muted);
      }
    }

    tr {
      &.loading {
        td {
          border-bottom: none;
        }
      }

      &.total {
        background: var(--input-background);

        .spacer {
          background: white;
        }
      }

      > *:not(.spacer) {
        border-bottom: 1px solid var(--text-muted);
      }
    }

    tr {
      transition: box-shadow 0.5s ease-in-out;

      .hover-icon {
        position: relative;
        display: inline-block;
        left: -20px;
        width: 0px;
        margin-right: 0px;
        color: var(--text-active);
        opacity: 0;
        vertical-align: middle;
        overflow: hidden;
        transition: left 0.3s ease-in-out, width 0.3s ease-in-out,
          margin 0.3s ease-in-out, opacity 0.4s ease-in-out;

        svg {
          width: 22px;
          height: 22px;
        }
      }

      .main-column {
        position: relative;
        left: 0;
        display: inline-block;
        white-space: nowrap;
        vertical-align: middle;
        transition: left 0.3s ease-in-out;
      }
    }
  }

  .sort-up {
    position: relative;
    top: 1px;
    left: 8px;
    transform: rotate(180deg);
  }

  .sort-down {
    position: relative;
    top: -2px;
    left: 8px;
  }

  .sort-none {
    display: none;
  }

  .spacer {
    width: 30px;
    min-width: 30px;
    padding: 0;
  }
`

export const directTableInteractiveStyles = `
  tbody {
    tr {
      cursor: pointer;

      &:hover {
        box-shadow: 0px 1px 6px 0 rgba(156, 178, 211, 0.24);
        z-index: 1;

        .hover-icon {
          left: 0;
          width: 22px;
          margin-right: -22px;
          opacity: 1;
        }

        .main-column {
          left: 22px;
        }
      }
    }
  }
`

export const directFilterStyles = `
  display: inline-block;
  position: relative;
  flex: 1;
  margin-left: 10px;

  button.submit,
  button.cancel {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    height: 46px;
    background: transparent;
    border: none;
    outline: none;
    appearance: none;

    svg {
      width: 22px;
      height: 22px;
    }
  }

  button.submit {
    justify-content: flex-end;
    left: 0;
    width: 46px;
  }

  button.cancel {
    justify-content: flex-start;
    right: 0;
    width: 40px;
    opacity: 0.4;
  }

  input {
    width: 100%;
    height: 46px;
    padding: 0 52px;
    background: var(--body-background);
    border: none;
    border-radius: 4px;

    &::placeholder {
      color: var(--text-secondary);
    }
  }

  select {
    width: 100%;
    max-width: 320px;
    height: 46px;
    padding: 14px 14px 10px;
    border: none;
    border-radius: 4px;
    background: var(--body-background);
  }
`

export const directPaginationStyles = `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -10px;

  .prev,
  .next {
    padding: 10px;
    border: none;
    background: transparent;

    svg {
      width: 22px;
      height: 22px;
    }
  }

  .next {
    svg {
      transform: rotate(180deg);
    }
  }

  .page {
    white-space: nowrap;

    input {
      width: 26px;
      height: 26px;
      padding-top: 5px;
      margin: 0 8px;
      border: 2px solid var(--text-muted);
      border-radius: 4px;
      background: var(--input-background);
      text-align: center;

      &.large {
        width: 36px;
      }
    }
  }
`

export const SortColumn = (props) => {
  const {
    className,
    element,
    field,
    label,
    title,
    defaultSort,
    onChange,
    sort,
    setSort,
    order,
    setOrder,
  } = props

  const sortClick = () => {
    if (typeof onChange === 'function') {
      onChange()
    }
    if (field === order) {
      if (sort === 'asc') {
        setSort('desc')
      } else {
        setSort('asc')
      }
    } else {
      setOrder(field)
      setSort(defaultSort)
    }
  }

  const sortKey = (event) => {
    if (event.key === 'Enter' || event.key === 'Space') {
      sortClick(field)()
    }
  }

  const sortArrowClass = () => {
    if (field === order) {
      if (sort === 'asc') {
        return 'sort-up'
      } else {
        return 'sort-down'
      }
    } else {
      return 'sort-none'
    }
  }

  if (element === 'th') {
    return (
      <th
        className={className}
        onClick={sortClick}
        onKeyDown={sortKey}
        title={title}
      >
        {label}
        <SVG
          className={sortArrowClass()}
          src="/images/icons/arrow-sort-down.svg"
        />
      </th>
    )
  } else {
    return (
      <td
        className={className}
        onClick={sortClick}
        onKeyDown={sortKey}
        title={title}
        role="presentation"
      >
        {label}
        <SVG
          className={sortArrowClass()}
          src="/images/icons/arrow-sort-down.svg"
        />
      </td>
    )
  }
}
