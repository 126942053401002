import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import Select from 'react-select'
import SVG from 'react-inlinesvg'
import { useRerender } from '../hooks'
import { getValue } from '../../../utils/helpers'

const Container = styled.div`
  .approved-publisher {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px;
    margin-bottom: 8px;
    border-radius: 4px;
    border: 1px solid var(--input-border);
    color: var(--text-primary);
    background: var(--input-background);
    font-weight: 500;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      padding: 0;
      border: none;
      border-radius: 10px;
      background: var(--text-secondary-light);
      transition: background 0.3s;

      &:hover {
        background: var(--text-primary);
      }
    }

    svg {
      width: 8px;
      color: var(--input-background);
    }
  }
`

const SelectFormGroup = styled.div.attrs((props) => ({
  className: `form-group select
    ${props.menuOpen ? 'menu-open' : ''}
    ${props.hasValue ? 'has-value' : ''}
    ${props.disabled ? 'disabled' : ''}`,
}))`
  position: relative;
  width: 50%;
  margin-bottom: 36px;
  border-radius: 6px;

  @media (max-width: 767px) {
    width: 100%;
  }

  .form-control {
    padding: 0;
    border: none;
    color: var(--text-primary);
    background: var(--input-background);
    cursor: pointer;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 12px;
    right: 12px;
    width: 20px;
    height: 20px;
    background-image: url('/images/icons/arrow-down.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    pointer-events: none;
  }

  label {
    position: absolute;
    top: 12px;
    left: 20px;
    color: var(--text-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    white-space: nowrap;
    pointer-events: none;
    transition: transform 0.3s ease-in-out;
    transform-origin: top left;
  }

  &.menu-open {
    .form-control {
      background: white;
      outline: none;
      box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
    }

    &:after {
      transform: rotate(180deg);
    }
  }

  &.menu-open,
  &.has-value {
    label {
      transform: translate(-20px, -32px) scale(0.7);
    }
  }

  &.disabled {
    .form-control {
      background: #e9ecef;
    }
  }

  [class$='-menu'] {
    background: var(--input-background);
  }

  [class$='-Option'] {
    color: var(--text-primary);
    background: transparent;
  }

  [class$='-Option']:hover {
    background: var(--input-background-hover);
  }

  .hint {
    padding-top: 4px;
    color: var(--text-secondary);
    font-size: 10px;
    line-height: 13px;
  }
`

const styles = {
  control: () => ({
    height: 44,
    border: 'none',
    borderRadius: 6,
    fontSize: 14,
    color: 'inherit',
    background: 'transparent',
  }),

  valueContainer: () => ({
    height: 44,
    padding: '12px 20px',
  }),

  singleValue: () => ({
    maxWidth: 'calc(100% - 20px)',
    height: 21,
    marginBottom: -21,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),

  input: () => ({
    padding: 0,
    paddingRight: 10,
    margin: 0,
  }),

  menu: (provided) => ({
    ...provided,
    padding: 4,
    borderRadius: 6,
    fontSize: 14,
    boxShadow: '2px 6px 6px 0 rgba(40, 46, 103, 0.06)',
  }),

  menuList: (provided) => ({
    ...provided,
    maxHeight: 200,
    padding: 0,
    border: 'none',
  }),

  option: (provided) => ({
    padding: 6,
    borderRadius: 4,
    cursor: 'pointer',
  }),
}

const components = {
  IndicatorsContainer: () => null,
}

export const PublisherSelect = (props) => {
  const {
    form,
    publishers,
    exclude,
    wrapperClassName,
    inputClassName,
    labelClassName,
  } = props

  const [value] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)

  const rerender = useRerender()

  const input = useRef()
  const id = 'publisher_select'

  const publisherUuids = getValue(form.getValues, 'publisherUuids') || []
  const publisherByUuid = (uuid) =>
    publishers.find((publisher) => publisher.uuid === uuid) || {}

  const addPublisher = ({ value }) => {
    if (!publisherUuids.includes(value)) {
      const newPublisherUuids = [...publisherUuids, value]
      form.setValue('publisherUuids', newPublisherUuids)
      rerender()
    }
  }
  const removePublisher = (index) => () => {
    const newPublisherUuids = [...publisherUuids]
    newPublisherUuids.splice(index, 1)
    form.setValue('publisherUuids', newPublisherUuids)
    rerender()
  }

  return (
    <Container>
      <SelectFormGroup
        hasValue={true}
        menuOpen={menuOpen}
        className={wrapperClassName || ''}
      >
        <Select
          id={id}
          className={`form-control ${inputClassName || ''}`}
          styles={styles}
          components={components}
          placeholder="Search to add partner"
          noOptionsMessage={() => 'No partners found'}
          options={publishers
            ?.filter(
              (publisher) =>
                !exclude?.includes(publisher.uuid) &&
                !publisherUuids?.includes(publisher.uuid)
            )
            ?.map((publisher) => ({
              value: publisher.uuid,
              label: publisher.name,
            }))}
          value={value}
          onMenuOpen={() => setMenuOpen(true)}
          onMenuClose={() => setMenuOpen(false)}
          onChange={addPublisher}
          isSearchable={true}
        />
        <label className={`string ${labelClassName || ''}`} htmlFor={id}>
          Add partners
        </label>
      </SelectFormGroup>

      <input type="hidden" ref={form.register('publisherUuids')} />

      {publisherUuids.length > 0 && (
        <div className="label mt-4">Approved partners</div>
      )}
      {publisherUuids.map((uuid, index) => (
        <div className="approved-publisher" key={uuid}>
          {publisherByUuid(uuid).name}
          <button type="button" onClick={removePublisher(index)}>
            <SVG src="/images/icons/cancel.svg" />
          </button>
        </div>
      ))}
    </Container>
  )
}
