import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  font-size: 12px;
  color: var(--text-secondary);

  span + span {
    &:before {
      padding: 0 8px;
      content: ' / ';
      color: var(--text-secondary-light);
    }
  }

  a {
    color: var(--text-secondary);
    font: inherit;

    &:hover {
      color: var(--text-secondary-hover);
    }
  }
`

export const Breadcrumbs = (props) => {
  const { crumbs } = props

  return (
    <Container className='breadcrumbs'>
      {Object.keys(crumbs).map((key) => (
        <Breadcrumb key={key} label={key} target={crumbs[key]} />
      ))}
    </Container>
  )
}

const Breadcrumb = (props) => {
  const { label, target } = props

  if (target) {
    return (
      <span>
        <a href={target}>{label}</a>
      </span>
    )
  } else {
    return <span>{label}</span>
  }
}
