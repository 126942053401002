import React from 'react'
import { Controller } from 'react-hook-form'
import styled from 'styled-components'
import { useRerender } from '../hooks'
import { getValue, getError } from '../../../utils/helpers'

const RadioFormGroup = styled.div.attrs((props) => ({
  className: 'form-group boolean',
}))`
  position: relative;
  margin-bottom: 36px;

  .custom-control {
    position: relative;
    white-space: nowrap;
  }

  .custom-control-input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
  }

  .custom-control-indicator {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 32px;
    border: 2px solid var(--text-secondary-light);
    border-radius: 16px;
    vertical-align: top;
    pointer-events: none;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 7px;
      left: 7px;
      width: 14px;
      height: 14px;
      background: var(--text-primary);
      border-radius: 7px;
      opacity: 0;
      transition: opacity 0.15s ease-in-out;
    }

    &:hover {
      border-color: var(--text-primary);
    }
  }

  .custom-control-input:checked + .custom-control-indicator {
    border-color: var(--text-primary);

    &:after {
      opacity: 1;
    }
  }

  .custom-control-indicator ~ .custom-control-label {
    display: inline-block;
    position: relative;
    top: -2px;
    padding-left: 10px;
    margin-right: 24px;
    white-space: normal;
    cursor: pointer;
  }

  .custom-control-label {
    font-size: 12px;
  }
`

export const Radio = (props) => {
  const {
    form,
    name,
    value,
    Label,
    label,
    onChange,
    validation,
    wrapperClassName,
    inputClassName,
    labelClassName,
  } = props

  const { setValue, getValues, errors } = form

  const rerender = useRerender()

  const onClick = () => {
    setValue(name, value)
    if (onChange) {
      onChange(value)
    }
    rerender()
  }

  const checked = getValue(getValues, name) === value

  const id = `${name.toLowerCase()}_${value.toLowerCase()}`
  const error = getError(errors, name)

  return (
    <RadioFormGroup className={wrapperClassName || ''}>
      <div className="custom-control custom-checkbox">
        <Controller
          id={id}
          control={form.control}
          name={name}
          rules={validation || {}}
          render={({ value: radioValue }) => (
            <input
              type="radio"
              id={id}
              className={`custom-control-input boolean ${inputClassName || ''}`}
              name={name}
              checked={checked}
              onChange={onClick}
            />
          )}
        />
        <div className="custom-control-indicator"></div>
        {Label ? (
          <div
            className={`custom-control-label boolean ${labelClassName || ''}`}
          >
            <Label />
          </div>
        ) : (
          <div
            className={`custom-control-label boolean ${labelClassName || ''}`}
            dangerouslySetInnerHTML={{ __html: label }}
          />
        )}
        {error && error.type === 'required' && (
          <div className="validation-error">This value is required.</div>
        )}
      </div>
    </RadioFormGroup>
  )
}
