import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { compact, pick } from 'lodash'
import { getValue, getError } from '../../../utils/helpers'

export const TextFormGroup = styled.div.attrs((props) => ({
  className: 'form-group',
}))`
  position: relative;
  margin-bottom: 36px;

  input {
    height: 44px;
    padding: 12px 20px;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    color: var(--text-primary) !important;
    background: var(--input-background);
  }

  label {
    position: absolute;
    top: 12px;
    left: 20px;
    color: var(--text-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    white-space: nowrap;
    pointer-events: none;
    transition: transform 0.3s ease-in-out;
    transform-origin: top left;
  }

  input:focus ~ label,
  input.has-value ~ label {
    transform: translate(-20px, -32px) scale(0.7);
  }

  .hint {
    padding-top: 4px;
    color: var(--text-secondary);
    font-size: 10px !important;
    line-height: 13px;
  }
`

export const TextField = (props) => {
  const {
    form,
    validation,
    name,
    label,
    hint,
    disabled,
    autocomplete,
    onChange,
    idPrefix,
    wrapperClassName,
  } = props
  const [hasValue, setHasValue] = useState(false)

  const updateHasValue = () => {
    setHasValue(!!getValue(form.getValues, name))
  }

  useEffect(updateHasValue, [name, form.getValues])

  const id = (idPrefix || '') + name.toLowerCase()

  const inputProps = pick(props, ['name', 'type', 'placeholder'])
  inputProps.ref = form.register(validation)
  inputProps.type = inputProps.type || 'text'
  inputProps.className = compact([
    'form-control',
    props.inputClassName || '',
    hasValue && 'has-value',
  ]).join(' ')
  inputProps.readOnly = disabled
  if (autocomplete) inputProps.autoComplete = autocomplete

  inputProps.onChange = () => {
    updateHasValue()

    if (onChange) {
      onChange()
    }
  }

  const error = getError(form.errors, name)

  return (
    <TextFormGroup className={wrapperClassName || ''}>
      <input id={id} {...inputProps} />
      <label htmlFor={id}>{label}</label>
      {hint && <div className="hint">{hint}</div>}
      {error && error.message && (
        <div className="validation-error">{error.message}</div>
      )}
    </TextFormGroup>
  )
}
