import moment from 'moment'
import { formatStatValue } from './stats'

export const formatStatTotal = (value, currency) =>
  `${formatStatValue(value || 0, true)} ${currency}`

export const formatChartDataFromStats = (
  stats,
  valueFields,
  startDate,
  endDate
) => {
  const result = []
  let day, nextDay
  let label, stat, entry

  const findStat = (date) => stats.find((stat) => stat.date === date)

  day = new Date(startDate)
  while (day <= endDate) {
    label = moment(day).format('D MMM')
    stat = findStat(moment(day).format('YYYY-MM-DD'))

    entry = { label }
    for (const key in valueFields) {
      if (stat) {
        entry[key] = parseFloat(stat[valueFields[key]]) || 0
      } else {
        entry[key] = 0
      }
    }
    result.push(entry)

    nextDay = day.setDate(day.getDate() + 1)
    day = new Date(nextDay)
  }

  return result
}

export const formatChartDataFromNewOldStats = (
  stats,
  oldStats,
  valueFields,
  oldValueFields,
  startDate,
  endDate
) => {
  const result = []
  let day, nextDay
  let label, stat, oldStat, entry

  const findStat = (date) => stats.find((stat) => stat.date === date)
  const findOldStat = (date) => oldStats.find((stat) => stat.date === date)
  const dateDiff = moment.duration(moment(endDate) - moment(startDate))

  day = new Date(startDate)
  while (day <= endDate) {
    label = `${moment(day).format('D MMM')} (${moment(day)
      .subtract(dateDiff)
      .format('D MMM')})`
    stat = findStat(moment(day).format('YYYY-MM-DD'))
    oldStat = findOldStat(moment(day).subtract(dateDiff).format('YYYY-MM-DD'))

    entry = { label }
    for (const key in valueFields) {
      if (stat) {
        entry[key] = parseFloat(stat[valueFields[key]]) || 0
      } else {
        entry[key] = 0
      }
    }
    for (const key in oldValueFields) {
      if (oldStat) {
        entry[key] = parseFloat(oldStat[oldValueFields[key]]) || 0
      } else {
        entry[key] = 0
      }
    }
    result.push(entry)

    nextDay = day.setDate(day.getDate() + 1)
    day = new Date(nextDay)
  }

  return result
}
