import React, { useState, useEffect } from 'react'
import { Controller } from 'react-hook-form'
import styled from 'styled-components'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { getValue } from '../../../utils/helpers'

import 'react-datepicker/dist/react-datepicker.css'

const DateFormGroup = styled.div.attrs((props) => ({
  className: `form-group date
    ${props.pickerOpen ? 'picker-open' : ''}
    ${props.hasValue ? 'has-value' : ''}
    ${props.allowBlank ? 'allow-blank' : ''}`,
}))`
  position: relative;
  margin-bottom: 36px;

  .react-datepicker-wrapper {
    width: 100%;
  }

  input {
    height: 44px;
    padding: 12px 20px;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    color: var(--text-primary);
    background: var(--input-background);
  }

  label {
    position: absolute;
    top: 12px;
    left: 20px;
    color: var(--text-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    white-space: nowrap;
    pointer-events: none;
    transition: top 0.3s ease-in-out, left 0.3s ease-in-out,
      font-size 0.2s 0.1s ease-in-out, font-weight 0.2s 0.1s ease-in-out;
  }

  &.picker-open,
  &.has-value,
  &.allow-blank {
    label {
      top: -24px;
      left: 0;
      font-size: 10px;
      font-weight: 500;
    }
  }

  .react-datepicker {
    border: none;
    border-radius: 6px;
    box-shadow: 1px 3px 6px 0 rgba(156, 178, 211, 0.5);
    font-family: inherit;

    .react-datepicker__triangle {
      border-bottom-color: var(--body-background);

      &:before {
        border-bottom-color: var(--body-background);
      }
    }

    .react-datepicker__header {
      border: none;
      background: var(--body-background);

      .react-datepicker__current-month {
        color: var(--text-primary);
      }
    }

    .react-datepicker__header__dropdown {
      margin: 10px 0;

      .react-datepicker__month-read-view--down-arrow,
      .react-datepicker__year-read-view--down-arrow {
        top: 7px;
        border-width: 5px;
      }

      .react-datepicker__month-read-view--down-arrow {
        margin-left: 22px;
      }

      .react-datepicker__year-read-view--down-arrow {
        margin-left: 24px;
      }
    }

    .react-datepicker__day--selected {
      background-color: var(--text-primary);

      &:hover {
        background-color: var(--text-primary-hover);
      }
    }
  }

  .clear-value {
    position: absolute;
    top: 11px;
    right: 6px;
    border: none;
    color: inherit;
    background: transparent;
    font-weight: bold;
  }
`

export const DateField = (props) => {
  const {
    form,
    name,
    label,
    clearable,
    allowBlank,
    onChange,
    inputProps,
    startDate,
    endDate,
  } = props
  const [hasValue, setHasValue] = useState(false)
  const [pickerOpen, setPickerOpen] = useState(false)

  const updateHasValue = () => {
    setHasValue(!!getValue(form.getValues, name))
  }

  useEffect(updateHasValue, [name, form.getValues])

  const id = name.toLowerCase()

  const inputOnChange = (controlOnChange) => (date) => {
    let value

    if (allowBlank) {
      value = date ? moment(date).format('MM/DD/YYYY') : undefined
    } else {
      value = moment(date || undefined).format('MM/DD/YYYY')
    }

    controlOnChange(value)

    updateHasValue()

    if (onChange) {
      onChange()
    }
  }

  const clearValue = (event) => {
    event.preventDefault()
    form.setValue(name, undefined)
    setHasValue(false)
  }

  if (inputProps) {
    if (startDate) {
      inputProps.startDate = new Date(getValue(form.getValues, startDate))
    }
    if (endDate) {
      inputProps.endDate = new Date(getValue(form.getValues, endDate))
    }
  }

  return (
    <DateFormGroup
      pickerOpen={pickerOpen}
      hasValue={hasValue}
      allowBlank={allowBlank}
    >
      <Controller
        id={id}
        control={form.control}
        name={props.name}
        render={({ value, onChange, onBlur }) => (
          <Render
            {...{
              value,
              onChange,
              onBlur,
              inputOnChange,
              setPickerOpen,
              inputProps,
              allowBlank,
            }}
          />
        )}
      />
      {clearable && hasValue && (
        <button className="clear-value" onClick={clearValue}>
          ✕
        </button>
      )}
      <label htmlFor={id}>{label}</label>
    </DateFormGroup>
  )
}

const Render = (props) => {
  const { onChange, inputOnChange, setPickerOpen, inputProps, allowBlank } =
    props
  let { value } = props

  if (typeof value === 'string') {
    value = new Date(value)
  }

  return (
    <DatePicker
      selected={value}
      onChange={inputOnChange(onChange)}
      onCalendarOpen={() => setPickerOpen(true)}
      onCalendarClose={() => setPickerOpen(false)}
      minDate={new Date()}
      showMonthDropdown={true}
      showYearDropdown={true}
      className="form-control"
      placeholderText={allowBlank}
      {...inputProps}
    />
  )
}
