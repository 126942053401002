import React, { useState } from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { Dialog, SpinnerCirc } from '../elements'
import { getCurrentUser } from '../../../services/auth'
import { postRequest } from '../../../services/api'

const AddPaymentMethodContainer = styled.div`
  .StripeElement {
    padding-bottom: 4px;
    border-bottom: 3px solid var(--text-secondary-light);
  }

  .spinner-border {
    width: 18px;
    height: 18px;
    margin-bottom: -2px;
  }

  .dialog-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .provider-note {
    .stripe-logo {
      display: inline;
      width: 50px;
      height: 16px;
      vertical-align: text-bottom;
    }
  }
`

const cardStyle = {
  hidePostalCode: true,
  style: {
    base: {
      color: '#38445f',
      backgroundColor: 'white',
      fontFamily: 'Helvetica, Arial, sans-serif',
      fontSize: '18px',

      '::placeholder': {
        color: '#636891',
      },
    },
  },
}

export const AddPaymentMethodDialog = (props) => {
  const { show, toggle, onDone } = props

  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState('')

  const stripe = useStripe()
  const elements = useElements()

  const onSubmit = async (event) => {
    setError('')
    setSubmitting(true)

    try {
      const currentUser = getCurrentUser()
      const card = elements.getElement(CardElement)
      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: card,
        billing_details: {
          name: currentUser.name,
        },
      })

      const paymentMethodId = result?.paymentMethod?.id
      if (!paymentMethodId) {
        throw new Error(result?.error?.message || 'Something went wrong')
      }

      const response = await postRequest('/advertiser/billing/methods', {
        paymentMethod: {
          id: paymentMethodId,
        },
      })
      if (!response?.data?.success) {
        throw new Error(response?.message || 'Something went wrong')
      }

      setSubmitting(false)
      toggle(false)
      card.clear()

      if (onDone) {
        onDone()
      }
    } catch (error) {
      setError(error.message)
      setSubmitting(false)
    }
  }

  const onStripeCardChange = async (event) => {
    setError('')
  }

  return (
    <Dialog show={show} toggle={toggle}>
      <AddPaymentMethodContainer>
        <h4 className="mt--25 mb-30">New payment method</h4>
        <CardElement options={cardStyle} onChange={onStripeCardChange} />
        {error && <div className="text-danger mt-2 mb--20">{error}</div>}
        <div className="dialog-actions">
          <div className="provider-note">
            Powered by
            <SVG src="/images/stripe.svg" className="stripe-logo" />
          </div>
          <div>
            {submitting && <SpinnerCirc />}
            <button
              className="btn btn-primary ml-3"
              onClick={onSubmit}
              disabled={submitting}
            >
              Save
            </button>
            <button
              className="btn btn-secondary ml-3"
              onClick={() => toggle(false)}
              disabled={submitting}
            >
              Cancel
            </button>
          </div>
        </div>
      </AddPaymentMethodContainer>
    </Dialog>
  )
}
