import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Controller } from 'react-hook-form'
import MaskedInput from 'react-text-mask'
import { compact } from 'lodash'
import { getValue, getError } from '../../../utils/helpers'

const MaskFormGroup = styled.div.attrs((props) => ({
  className: 'form-group masked',
}))`
  position: relative;
  margin-bottom: 36px;

  input {
    height: 44px;
    padding: 12px 20px;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    color: var(--text-primary);
    background: var(--input-background);
  }

  label {
    position: absolute;
    top: 12px;
    left: 20px;
    color: var(--text-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    white-space: nowrap;
    pointer-events: none;
    transition: transform 0.3s ease-in-out;
    transform-origin: top left;
  }

  input:focus ~ label,
  input.has-value ~ label {
    transform: translate(-20px, -32px) scale(0.7);
  }

  .hint {
    padding-top: 4px;
    color: var(--text-secondary);
    font-size: 10px;
    line-height: 13px;
  }

  .validation-error {
    padding-left: 0;
    font-size: 10px;
  }
`

export const MaskField = (props) => {
  const { form, name, label, hint, mask, validation } = props

  const [hasValue, setHasValue] = useState(false)

  const updateHasValue = () => {
    setHasValue(!!getValue(form.getValues, name))
  }

  useEffect(updateHasValue, [name, form.getValues])

  const onChange = (event) => {
    const newValue = event.target.value
    form.setValue(name, newValue)
    setHasValue(!!newValue)

    if (props.onChange) {
      props.onChange(event)
    }
  }

  const value = getValue(form.getValues, name)

  const id = name.toLowerCase()
  const error = getError(form.errors, name)
  const inputClassName = compact([
    'form-control',
    props.inputClassName || '',
    hasValue && 'has-value',
  ]).join(' ')

  return (
    <MaskFormGroup>
      <Controller
        id={id}
        control={form.control}
        name={name}
        rules={validation || {}}
        render={({ onBlur }) => (
          <MaskedInput
            mask={mask}
            guide={false}
            value={value || ''}
            className={inputClassName}
            onChange={onChange}
            onBlur={onBlur}
          />
        )}
      />
      <label htmlFor={id}>{label}</label>
      {hint && <div className="hint">{hint}</div>}
      {error && error.type === 'required' && (
        <div className="validation-error">This value is required.</div>
      )}
    </MaskFormGroup>
  )
}
