export const saveValue = (key, value) => localStorage.setItem(key, value)

export const loadValue = (key, defaultValue = undefined) =>
  localStorage.getItem(key) || defaultValue

export const loadDateValue = (key, defaultValue = undefined) => {
  const value = localStorage.getItem(key)
  if (value) {
    return new Date(value)
  } else {
    return defaultValue
  }
}

export const loadObjectValue = (key, defaultValue = {}) =>
  JSON.parse(localStorage.getItem(key) || JSON.stringify(defaultValue))

export const forgetValue = (key) => localStorage.removeItem(key)
