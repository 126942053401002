import { get } from 'lodash'
import { getCountryName } from '../countries'

export const getValue = (getValues, name) => {
  const values = getValues()

  if (!values) {
    return undefined
  }

  if (name.indexOf('.') !== -1) {
    return get(values, name)
  } else {
    return values[name]
  }
}

export const getError = (errors, name) => {
  if (!errors) {
    return undefined
  }

  if (name.indexOf('.') !== -1) {
    return get(errors, name)
  } else {
    return errors[name]
  }
}

export const getOption = (options, value) =>
  options?.find((option) => option.value === value)

export const getCompany = (companies, name) => {
  if (!companies || !name) {
    return undefined
  }

  const canonicalName = name.trim().toLowerCase()
  return companies.find(
    (company) => company.name.trim().toLowerCase() === canonicalName
  )
}

export const getPerson = (persons, name) => {
  if (!persons || !name) {
    return undefined
  }

  const canonicalName = name.trim().toLowerCase()
  return persons.find(
    (person) => person.name.trim().toLowerCase() === canonicalName
  )
}

export const touchTextField = (id) => {
  const field = document.querySelector(`[id="${id}"]`)
  if (field) {
    if (field.value) {
      field.classList.add('has-value')
    } else {
      field.classList.remove('has-value')
    }
  }
}

export const touchCountryField = (name) => {
  const field = document.querySelector(`[name="${name}"]`)
  const country = getCountryName(field.value) || ''

  const getValueContainer = () =>
    field?.previousSibling?.querySelectorAll('div')[2]

  const setValue = (value) => {
    const valueContainer = getValueContainer()
    if (valueContainer) {
      valueContainer.innerText = value
    }
  }

  setValue(country)
}

export const clearUndefinedNestedErrors = (form, parent) => {
  if (form && form.errors && form.errors[parent]) {
    for (const key in form.errors[parent]) {
      if (form.errors[parent][key] !== undefined) {
        return
      }
    }
    form.clearErrors(parent)
  }
}
