import React from 'react'
import { Controller } from 'react-hook-form'
import styled from 'styled-components'
import { useRerender } from '../hooks'
import { getValue, getError } from '../../../utils/helpers'

const RadioFormGroup = styled.div.attrs((props) => ({
  className: 'form-group boolean block-radio',
}))`
  position: relative;
  margin: 0 14px 28px;
  border: 2px solid
    ${(props) =>
      props.checked ? 'var(--text-primary)' : 'var(--text-secondary-light)'};
  border-radius: 6px;
  transition: border-color 0.3s;

  .custom-control {
    position: relative;
    white-space: nowrap;
  }

  .custom-control-input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
  }

  .custom-control-indicator {
    position: absolute;
    top: 8px;
    right: 8px;
    display: inline-block;
    width: 22px;
    height: 22px;
    border-radius: 12px;
    background: transparent;
    vertical-align: top;
    pointer-events: none;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      background: white;
      border-radius: 1px;
      opacity: 0;
      transition: opacity 0.15s ease-in-out;
    }

    &:before {
      top: 10px;
      left: 7px;
      width: 2px;
      height: 6px;
      transform: rotate(140deg);
    }

    &:after {
      top: 5px;
      left: 12px;
      width: 2px;
      height: 12px;
      transform: rotate(40deg);
    }

    &:hover {
      background: var(--text-primary);
    }
  }

  .custom-control-input:checked + .custom-control-indicator {
    background: var(--text-primary);

    &:before,
    &:after {
      opacity: 1;
    }
  }

  .custom-control-indicator ~ .custom-control-label {
    white-space: normal;
    cursor: pointer;
  }

  .custom-control-label {
    padding: 4px 8px;
    font-size: 12px;
  }
`

export const BlockRadio = (props) => {
  const {
    form,
    name,
    value,
    Label,
    label,
    onChange,
    validation,
    wrapperClassName,
    inputClassName,
    labelClassName,
  } = props

  const { setValue, getValues, errors } = form

  const rerender = useRerender()

  const onClick = () => {
    setValue(name, value)
    if (onChange) {
      onChange(value)
    }
    rerender()
  }

  const checked = getValue(getValues, name) === value

  const id = `${name.toLowerCase()}_${value.toLowerCase()}`
  const error = getError(errors, name)

  return (
    <RadioFormGroup className={wrapperClassName || ''} checked={checked}>
      <div className="custom-control custom-checkbox">
        <Controller
          id={id}
          control={form.control}
          name={name}
          rules={validation || {}}
          render={({ value: radioValue }) => (
            <input
              type="radio"
              id={id}
              className={`custom-control-input boolean ${inputClassName || ''}`}
              name={name}
              checked={checked}
              onChange={onClick}
            />
          )}
        />
        <div className="custom-control-indicator"></div>
        {Label ? (
          <div
            className={`custom-control-label boolean ${labelClassName || ''}`}
          >
            <Label />
          </div>
        ) : (
          <div
            className={`custom-control-label boolean ${labelClassName || ''}`}
            dangerouslySetInnerHTML={{ __html: label }}
          />
        )}
        {error && error.type === 'required' && (
          <div className="validation-error">This value is required.</div>
        )}
      </div>
    </RadioFormGroup>
  )
}
