import React from 'react'

export const SpinnerCirc = (props) => {
  if (props.show === false) {
    return null
  }

  return <div className="spinner-border" role="status" />
}

export const SpinnerRect = (props) => {
  if (props.show === false) {
    return null
  }

  return (
    <div className="spinner-rect" role="status">
      <div className="rect1"></div>
      <div className="rect2"></div>
      <div className="rect3"></div>
      <div className="rect4"></div>
      <div className="rect5"></div>
    </div>
  )
}
