import { useState, useEffect } from 'react'
import useIsMounted from 'ismounted'
import * as API from '../../../services/api'

export const useApiLoad = (path, shouldLoad, callback) => {
  const [loading, setLoading] = useState(false)
  const isMounted = useIsMounted()

  useEffect(() => {
    if (shouldLoad && !loading) {
      setLoading(true)
      API.getRequest(path).then((response) => {
        if (isMounted.current) {
          callback(response)
          setLoading(false)
        }
      })
    }
  }, [path, shouldLoad, callback, loading, isMounted])
}
