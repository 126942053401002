import React, { useContext } from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { appContext } from '../contexts'
import { copyToClipboard } from '../../../utils/helpers'
import { shortlinkHost } from '../../../utils/constants'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;

  .contract-shortlink-code,
  .shortlink-code {
    display: inline-block;
    height: 42px;
    padding: 8px;
    margin-right: 16px;
    margin-bottom: 8px;
    border: 2px dashed var(--input-border);
    border-radius: 4px;
    overflow: auto;
    background: var(--input-background);

    span {
      color: var(--text-primary-hover);
      font-weight: 600;
      vertical-align: middle;
    }

    button {
      padding: 0 8px;
      margin-left: 16px;
      border: none;
      color: var(--text-primary);
      background: transparent;
      outline: none;
      transition: color 0.3s ease-in-out;

      &:hover {
        color: var(--text-primary-hover);
      }

      svg {
        width: 16px;
        height: 16px;
        margin-top: -2px;
      }
    }
  }

  .contract-shortlink-code-send,
  .shortlink-code-send {
    display: inline-block;
    height: 42px;
    padding: 0 12px;
    border: none;
    border-radius: 4px;
    color: white;
    background: #ffc106;
    font-weight: bold;
    transition: background 0.3s ease-in-out;

    &:hover {
      color: white;
      background: #ffcb30;
    }

    svg {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
`

export const ContractShortlinkCode = (props) => {
  const { contract, share } = props

  const { showMessage } = useContext(appContext)

  return (
    <Container>
      <div className="contract-shortlink-code">
        <span>
          {shortlinkHost}/{contract.shortlinkCode}
        </span>
        <button
          onClick={copyToClipboard('.contract-shortlink-code span', () =>
            showMessage('Link copied to clipboard')
          )}
        >
          <SVG src="/images/icons/clipboard.svg" />
        </button>
      </div>
      <button
        type="button"
        className="contract-shortlink-code-send"
        onClick={share}
      >
        <SVG src="/images/icons/paper-plane.svg" />
        Send to email
      </button>
    </Container>
  )
}

export const ShortlinkCode = (props) => {
  const { code, share } = props

  const { showMessage } = useContext(appContext)

  return (
    <Container>
      <div className="shortlink-code">
        <span>
          {shortlinkHost}/{code}
        </span>
        <button
          onClick={copyToClipboard('.shortlink-code span', () =>
            showMessage('Link copied to clipboard')
          )}
        >
          <SVG src="/images/icons/clipboard.svg" />
        </button>
      </div>
      <button type="button" className="shortlink-code-send" onClick={share}>
        <SVG src="/images/icons/paper-plane.svg" />
        Send to email
      </button>
    </Container>
  )
}
