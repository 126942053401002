import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'

const Container = styled.div`
  .rule-info {
    .form-control {
      border-color: transparent;
      font-size: 13px;
    }
  }

  .rule-triggers,
  .rule-actions {
    padding: 8px;
    margin-top: 16px;
    border: 1px solid var(--input-border);
    color: var(--text-primary);
    background: var(--input-background);

    .row {
      align-items: center;
      margin-bottom: 8px;
    }

    .form-control {
      border-color: transparent;
      font-size: 13px;
    }

    .select-control {
      position: relative;

      select {
        padding-right: 24px;
      }

      &:after {
        content: '';
        position: absolute;
        top: 12px;
        right: 10px;
        display: block;
        width: 8px;
        height: 8px;
        border-right: 2px solid var(--text-primary-hover);
        border-bottom: 2px solid var(--text-primary-hover);
        transform: rotate(45deg);
      }
    }

    .bold {
      padding-left: 14px;
      font-weight: 600;
    }

    .remove {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      padding: 0;
      border: none;
      border-radius: 10px;
      background: var(--text-secondary-light);
      transition: background 0.3s;

      &:hover {
        background: var(--text-primary);
      }

      svg {
        width: 8px;
        color: var(--input-background);
      }
    }
  }

  .rule-actions {
    .row {
      margin-bottom: 0;
    }
  }
`

const defaultTrigger = {
  junction: 'and',
  key: 'itemName',
  rule: 'equals',
  value: '',
}

const defaultAction = {
  key: 'itemPayout',
  rule: 'set',
  value: '',
}

export const ConversionRule = (props) => {
  const { form } = props

  const [ruleName, setRuleName] = useState('New rule')

  const [triggers, setTriggers] = useState([{ ...defaultTrigger }])
  const updateTrigger = (index, field) => (event) => {
    const newTriggers = [...triggers]
    newTriggers[index][field] = event.target.value
    delete newTriggers[0].junction
    setTriggers(newTriggers)
  }
  const addTrigger = () => setTriggers([...triggers, { ...defaultTrigger }])
  const removeTrigger = (index) => () => {
    const newTriggers = [...triggers]
    newTriggers.splice(index, 1)
    setTriggers(newTriggers)
  }

  const [action, setAction] = useState({ ...defaultAction })
  const updateAction = (field) => (event) => {
    const newAction = { ...action }
    newAction[field] = event.target.value
    setAction(newAction)
  }

  useEffect(() => {
    form.setValue('data.conversionRules', [
      {
        name: ruleName,
        rule: {
          triggers,
          action,
        },
      },
    ])
  }, [form, ruleName, triggers, action])

  return (
    <Container>
      <div className="rule-info">
        <div className="row">
          <div className="col-md-5">
            <div className="label">Rule name</div>
            <input
              type="text"
              className="form-control"
              value={ruleName}
              onChange={(event) => setRuleName(event.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="rule-triggers">
        {triggers.map((trigger, index) => (
          <div className="row" key={index}>
            <div className="col-md-2">
              {index === 0 ? (
                <span className="bold">If</span>
              ) : (
                <div className="select-control">
                  <select
                    className="form-control"
                    value={trigger.junction}
                    onChange={updateTrigger(index, 'junction')}
                    onBlur={updateTrigger(index, 'junction')}
                  >
                    <option value="and">And</option>
                    <option value="or">Or</option>
                  </select>
                </div>
              )}
            </div>
            <div className="col-md-3">
              <div className="select-control">
                <select
                  className="form-control"
                  value={trigger.key}
                  onChange={updateTrigger(index, 'key')}
                  onBlur={updateTrigger(index, 'key')}
                >
                  <option value="itemName">Item name</option>
                  <option value="itemVariant">Item variant</option>
                  <option value="itemProductUrl">Item URL</option>
                  <option value="itemCategoryName">Item category</option>
                  <option value="callbackOrderCouponCode">Coupon code</option>
                  <option value="callbackGoalId">Goal ID</option>
                  <option value="callbackMarketingTag">Marketing tag</option>
                  <option value="callbackMarketingTagValue0">Tag value</option>
                  <option value="callbackMarketingTagValue1">
                    Tag value (+1 day)
                  </option>
                  <option value="callbackMarketingTagValue7">
                    Tag value (+7 days)
                  </option>
                  <option value="callbackMarketingTagValue14">
                    Tag value (+14 days)
                  </option>
                  <option value="callbackMarketingTagValue30">
                    Tag value (+30 days)
                  </option>
                </select>
              </div>
            </div>
            <div className="col-md-3">
              <div className="select-control">
                <select
                  className="form-control"
                  value={trigger.rule}
                  onChange={updateTrigger(index, 'rule')}
                  onBlur={updateTrigger(index, 'rule')}
                >
                  <option value="equals">Equals</option>
                  <option value="contains">Contains</option>
                  <option value="startsWith">Starts with</option>
                  <option value="endsWith">Ends with</option>
                </select>
              </div>
            </div>
            <div className="col-md-3">
              <input
                type="text"
                className="form-control"
                value={trigger.value}
                onChange={updateTrigger(index, 'value')}
                onBlur={updateTrigger(index, 'value')}
              />
            </div>
            <div className="col-md-1">
              {triggers.length > 1 && (
                <button
                  type="button"
                  className="remove"
                  onClick={removeTrigger(index)}
                >
                  <SVG src="/images/icons/cancel.svg" />
                </button>
              )}
            </div>
          </div>
        ))}
        <div className="row mb-0">
          <div className="col-md-3 offset-md-2">
            <button type="button" className="a" onClick={addTrigger}>
              + Add condition
            </button>
          </div>
        </div>
      </div>

      <div className="rule-actions">
        <div className="row mb-0">
          <div className="col-md-2">
            <span className="bold">Then</span>
          </div>
          <div className="col-md-3">
            <div className="select-control">
              <select
                className="form-control"
                value={action.key}
                onChange={updateAction('key')}
                onBlur={updateAction('key')}
              >
                <option value="itemPayout">Item payout</option>
                <option value="totalPayout">Total payout</option>
              </select>
            </div>
          </div>
          <div className="col-md-3">
            <div className="select-control">
              <select
                className="form-control"
                value={action.rule}
                onChange={updateAction('rule')}
                onBlur={updateAction('rule')}
              >
                <option value="set">Set to</option>
                <option value="reject">Reject</option>
              </select>
            </div>
          </div>
          <div className="col-md-3">
            <input
              type="text"
              className={`form-control ${
                action.rule === 'reject' ? 'd-none' : ''
              }`}
              value={action.value}
              onChange={updateAction('value')}
            />
          </div>
        </div>
      </div>

      <input type="hidden" ref={form.register('data.conversionRules')} />
    </Container>
  )
}
